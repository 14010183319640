//
// Procedures layout
//

body.layout-procedures .servico {
    svg {
        margin: 20px auto 0px auto!important;
        display: block;
        width: 5rem !important;
    }
}

#servicos {
    padding-bottom: 35px;

    .servico {
        background: white;
        margin: 0;

        svg {
            width: 4rem;
            margin-top: 1rem;
            margin-left: 1rem;
            color: $secondary;
        }

        .contents {
            padding: 20px 20px 25px 10px;
        }
    }
}
