//
// Guidelines collection page
//

.guidelines-main-nav {
  &__container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    @include media-breakpoint-down(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  &__title {
    @include font-size($h4-font-size);
    padding-bottom: $headings-margin-bottom;
  }
  &__nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $grid-gutter-width / 2;
  }
  &__link {
    border: solid $border-width;
    border-radius: $border-radius;
    text-align: center;
    @extend .btn-outline-primary;
    height: 100%;
    padding: $nav-link-padding-y * 1.5 $nav-link-padding-x;
  }
}