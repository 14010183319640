//
// Swiper styles
//

$themeColor: $primary !default;
$colors: (
  'white': $white,
  'black': $black,
) !default;

@import '../../../node_modules/swiper/swiper';
@import '../../../node_modules/swiper/components/pagination/pagination';

.swiper-slide {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
