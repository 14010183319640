.navbar {
  button.navbar-toggler {
    background: white;
  }
}

.navbar .navbar-nav li a {
  color: #fff !important;
  text-transform: uppercase;
  @include font-size($font-size-sm);
}

.navbar .navbar-nav li a:hover {
  color: #fff !important;
  text-decoration: $link-decoration;
}

.navbar .navbar-nav li.active a {
  background: transparent;
  color: #fff !important;
  font-weight: bold;
}

.navbar .navbar-nav li.active a:hover {
  background: transparent;
  color: #fff;
}

/*  Theme HTML Template */
@media only screen and (min-width: 768px) {
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-header .main-menu {
    top: 0px;
    width: 100%;
  }

  .main-menu .navbar-header {
    position: relative;
    float: none;
    display: block;
    text-align: right;
    width: 100%;
    padding: 0px;
    right: 0px;
    z-index: 12;
  }

  .main-menu .navbar-header .navbar-toggle {
    display: block;
    border: 1px solid #ffffff;
    float: left;
    height: 50px;
    width: 50px;
    padding-left: 12px;
    text-align: center;
    margin: 0px 0px 0px 0px;
    border-radius: 0px;
    background: #7b64cb;
  }

  .main-menu .navbar-header .navbar-toggle .icon-bar {
    background: #ffffff;
  }

  .main-menu .navbar-collapse > .navigation {
    float: none !important;
    margin: 0px !important;
    width: 100% !important;
    background: $secondary;
    border: 1px solid #ffffff;
    border-top: none;
  }

  .main-menu .navbar-collapse > .navigation > li {
    margin: 0px !important;
    float: none !important;
    width: 100%;
  }

  .main-menu .navigation > li > a,
  .main-menu .navigation > li > ul:before {
    border: none;
  }

  .main-menu .navbar-collapse > .navigation > li > a {
    padding: 10px 10px !important;
    border: none !important;
  }

  .main-menu .navigation li.dropdown > a:after,
  .main-menu .navigation > li.dropdown > a:before,
  .main-menu .navigation > li > ul > li > a::before,
  .main-menu .navigation > li > ul > li > ul > li > a::before {
    color: #ffffff !important;
    right: 15px;
    font-size: 16px;
    display: none !important;
  }

  .main-menu .navbar-collapse > .navigation > li > ul,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul {
    position: relative;
    border: none;
    float: none;
    visibility: visible;
    opacity: 1;
    display: none;
    margin: 0px;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    width: 100%;
    background: #e4b700;
    border-radius: 0px;
    border-top: 1px solid white !important;
    transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    -moz-transition: none !important;
  }

  .main-menu .navbar-collapse > .navigation > li,
  .main-menu .navbar-collapse > .navigation > li > ul > li,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li {
    border-top: 1px solid white !important;
    opacity: 1 !important;
    top: 0px !important;
    left: 0px !important;
    visibility: visible !important;
  }

  .main-menu .navbar-collapse > .navigation > li:first-child {
    border: none;
  }

  .main-menu .navbar-collapse > .navigation > li > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a {
    padding: 15px 10px !important;
    line-height: 22px;
    color: #ffffff;
    background: #7b64cb;
    text-align: left;
  }

  .main-header.style-two .main-menu .navigation > li > a {
    color: #ffffff !important;
  }

  .main-menu .navbar-collapse > .navigation > li > a:hover,
  .main-menu .navbar-collapse > .navigation > li > a:active,
  .main-menu .navbar-collapse > .navigation > li > a:focus {
    background: #1cc9ce;
  }

  .main-menu .navbar-collapse > .navigation > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
  .main-menu .navbar-collapse > .navigation > li.current > a,
  .main-menu .navbar-collapse > .navigation > li.current-menu-item > a {
    background: $secondary;
    color: #fff !important;
  }

  .main-menu .navbar-collapse > .navigation li.dropdown:after,
  .main-menu .navigation > li > ul:before {
    display: none !important;
  }

  .main-menu .navbar-collapse > .navigation li.dropdown .dropdown-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 12px;
    color: #ffffff;
  }

  .main-menu .navbar-collapse > .navigation li.current .dropdown-btn,
  .main-menu .navbar-collapse > .navigation li:hover .dropdown-btn {
    color: #ffffff;
  }
}

@media only screen and (max-width: 991px) {
    .main-header.style-two .main-menu {
      float: none !important;
    }
}


@-webkit-keyframes menu_sticky {
    0% {
      margin-top: -150px;
    }
  
    50% {
      margin-top: -130px;
    }
  
    100% {
      margin-top: 0;
    }
  }
  
  @keyframes menu_sticky {
    0% {
      margin-top: -150px;
    }
  
    50% {
      margin-top: -130px;
    }
  
    100% {
      margin-top: 0;
    }
  }