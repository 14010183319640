//
// Base
//

h1,
h2,
h3,
h4,
h5,
h6 {
  @include media-breakpoint-down(md) {
    text-align: left !important;
  }
}

h1 {
  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }
  color: $secondary;
}

h2 {
  color: $secondary;
}

h5 {
  color: $primary;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.btn {
  text-transform: uppercase;
  font-weight: $font-weight-bolder;
  font-size: $font-size-sm;
}

.progress {
  position: relative;
  height: 24px;
  .progress-bar {
    text-align: left;
    strong {
      padding-left: 8px;
    }
  }
  e {
    position: absolute;
    right: 2px;
    top: 2px;
    font-weight: 700;
    color: $secondary;
    background-color: white !important;
    z-index: 11111;
    width: 38px;
    height: 20px;
    line-height: 1.5;
    border-radius: 3px;
    text-align: center;
  }
}

.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile {
  display: none;
  @include media-breakpoint-down(md) {
    display: inherit;
  }
}

.vertical-center {
  display: flex;
  align-items: center;
}

.section.dark {
  color: $white;
  background-color: $dark;
}

.section-title h3 {
  font-weight: 700;
}

.section-title span {
  font-weight: 400;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.anim-3,
.anim-3-all * {
  transition: all 0.3s ease;
}

.anim-5,
.anim-5-all * {
  transition: all 0.5s ease;
}

.anim-7,
.anim-7-all * {
  transition: all 0.7s ease;
}

.section {
  padding: 2rem 0;
  @include media-breakpoint-down(md) {
    padding: 1rem 0;
  }
}

.p-0-mobile {
  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }
}

.page-action-bar {
  background-color: $white;
  z-index: 2;
  position: relative;
  @include media-breakpoint-down(md) {
    display: none;
  }
  a {
    display: flex;
    align-items: center;
    color: $primary;
    text-decoration: none;
    @include font-size($font-size-sm);
    svg {
      margin-right: .25rem;
    }
    &:hover {
      text-decoration: $link-decoration;
    }
  }
}

.page-header {
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  width: 100%;
  @include media-breakpoint-down(md) {
    background: $primary;
    position: relative !important;
  }
  background-color: $primary;
  &.page-header--transparent {
    @include media-breakpoint-up(md) {
      background-color: transparent;
    }
  }
}

.page-body {
  position: relative;
  .main-content {
    max-width: 100vw;
  }
}

.page-footer {
  @include font-size($font-size-sm);
  padding-top: 2rem;
  padding-bottom: 1rem;
  color: $light;
  &__widgets {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-gap: 2rem 3rem;
    grid-template-columns: 1fr .75fr 1fr 1fr;
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      grid-gap: 1rem;
      grid-template-columns: 1fr;
    }
  }
  p,
  a,
  li {
    color: #fff;
  }
  form#form_newsletter {
    position: relative;
    input#email {
      position: absolute;
      border-radius: 40px;
      background: white;
      border: 0;
      padding: 10px 17px;
      width: 100%;
      outline: none !important;
    }
    input[type='submit'] {
      position: absolute;
      border-radius: 40px;
      background: #00003a;
      border: 0;
      padding: 8px 14px;
      top: 2px;
      right: 2px;
      color: white;
    }
  }
}

.copyright-area {
  padding: 0.5rem 0;
  svg {
    fill: $gray-500;
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }
}