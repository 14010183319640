//
// Homepage layout
//

.banner-section {
  margin-top: -100px;
}

.banner-slider {
  @include media-breakpoint-up(md) {
    height: 540px;
    .swiper-slide {
      padding-top: 4rem;
      background-size: cover;
      background-position: center;
    }
  }
}

.banner-slider .slider-item {
  @include media-breakpoint-up(md) {
    height: 600px;
  }

  display: flex !important;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.banner-slider .slider-item:before {
  position: absolute;
  content: '';
  top: 0;
  height: 100%;
  width: 100%;
}

.banner-slider .content {
  position: relative;
  z-index: 2;

  @include media-breakpoint-down(md) {
    padding: 15px 15px 20px;
    backdrop-filter: blur(5px);
    background: #00003ac9;
  }
}

.banner-slider .content.style .offer-text {
  letter-spacing: 7px;
  margin-bottom: 5px;
}

.banner-slider .content.style .tag-text {
  font-weight: normal;
}

.banner-slider .content h2 {
  text-transform: capitalize;
  color: #fff;
}

.banner-slider .content p {
  color: #fff;
  line-height: 1.25;
}

.banner-slider .content .btn-white {
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  border: 1px solid #fff;
  transition: all 0.3s ease;
}

.banner-slider .content .btn-white:hover {
  background: #fff;
  color: #000;
}

.banner-slider .slick-dots {
  bottom: 20px;
}

.banner-slider .slick-dots li {
  margin: 0 3px;
}

.banner-slider .slick-dots li button:before {
  font-size: 15px;
}

.banner-slider .slick-dots li.slick-active button:before {
  color: #fff;
}

.banner-slider .slick-prev {
  left: 20px;
  z-index: 100;
}

.banner-slider .slick-next {
  right: 20px;
  z-index: 100;
}

.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}

section#depoimentos {
  background: rgb(191,2,52);
  background: linear-gradient(0deg, rgba(191,2,52,1) 0%, rgba(0,0,58,1) 100%);

  .slider-depoimentos {
    img {
      border-radius: 70px;
      width: 115px;
      height: 115px;
      margin-bottom: 15px;
      object-fit: cover;
    }
    p {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: 40%;
    top: 26%;
    width: 20px;
    height: 20px;
    z-index: 111111;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      right: 25%;
    }

    @media screen and (min-width: 768px) and (max-width: 979px) {
      right: 35%;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
  .swiper-button-prev {
    position: absolute;
    left: 40%;
    top: 26%;
    width: 20px;
    height: 20px;
    z-index: 111111;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      left: 25%;
    }

    @media screen and (min-width: 768px) and (max-width: 979px) {
      left: 35%;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .slider-parceiros {
    background: $primary;
    padding: 35px 10px;
    border-radius: 20px;
    margin-bottom: -47px;
    position: relative;
    z-index: 11;
  }
}

.depoimento {
  img {
    border-radius: 70px;
    width: 85px;
    height: 85px;
    margin: 15px 15px 0 0;
    object-fit: cover;
    float: left;
    background: #d2d2d2;
  }
}

section#blog {
  padding: 90px 0 60px;

  h3 a {
    color: $primary;
  }

  .post-blog {
    background: white;
    border-radius: 4px;
    overflow: hidden;

    img {
      margin-bottom: -30px;
    }

    span.data {
      background: #0f0f71;
      color: white !important;
      padding: 6px 14px;
      font-size: 1rem;
      border-radius: 5px 5px 0 0;
    }
  }

  .swiper-container {
    display: grid;
  }
}

.team-section {
  position: relative;
  background: #ffffff;
  z-index: 1;
}

.team-section .section-title h3 {
  position: relative;
  padding-bottom: 30px;

  @include media-breakpoint-down(md) {
    text-align: left !important;
  }
}

.team-section .section-title p {
  @include media-breakpoint-down(md) {
    text-align: left !important;
  }
}

.team-section .team-member {
  height: 100%;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: $box-shadow;
  @include font-size($font-size-sm);

  .doutor {
    height: inherit;
  }
}

.team-section .team-member .contents h4 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #000;
  position: relative;
}

.team-section .team-member .contents h4:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 1px;
  background: #777777;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.team-members .team-person {
  margin-top: 30px;
}

.team-members .team-person img {
  border-radius: 5px;
  width: 100%;
}

.team-members .team-person h6 {
  margin-top: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #000;
}

.team-members .team-person p {
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
}

.styled-pagination {
  padding-top: 50px;
}

.styled-pagination ul {
  padding-left: 0;
}

.styled-pagination ul li {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

.styled-pagination ul li a {
  position: relative;
  display: block;
  line-height: 50px;
  font-size: 16px;
  width: 50px;
  height: 50px;
  color: #777777;
  font-weight: 500;
  text-align: center;
  background: #f4f4f4;
  border-radius: 4px;
  transition: all 500ms ease;
}

.styled-pagination ul li a.prev,
.styled-pagination ul li a.next {
  font-size: 18px;
}

.styled-pagination ul li a:hover,
.styled-pagination ul li a.active {
  color: #ffffff;
  background: $secondary;
  transition: all 500ms ease;
}

@media only screen and (max-width: 991px) {
  .main-header .search-box {
    display: none;
  }

  .header-uper .logo {
    float: none !important;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .main-header.style-two .logo {
    float: none !important;
    text-align: center;
    margin-bottom: 20px;
  }

  .main-header.style-two .search-box-btn {
    top: 95px !important;
  }

  .fact-counter .column .item {
    margin-bottom: 40px !important;
  }

  .contact-area {
    margin-top: 70px !important;
    padding-left: 0px !important;
  }

  .footer-main .footer-top .social-links {
    margin-left: 0px !important;
  }

  .footer-bottom .footer-bottom-link {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-header {
    margin-bottom: 0px !important;
  }

  .main-header .logo {
    position: absolute !important;
    top: -5px;
    left: 30%;
  }

  .search_option {
    position: absolute !important;
    top: 2px;
    right: 5px;
  }

  .footer-main .footer-top .menu-link {
    margin-left: 0px !important;
  }

  .main-header.style-two .search-box-btn {
    top: 15px !important;
  }

  .footer-main .footer-top .gallery-widget {
    margin-left: 0px !important;
  }

  .service-tab-section .tab-list-column .tab-list,
  .service-tab-section .tab-content .inner-box {
    margin-left: 0px !important;
  }

  .header-uper .right-side {
    float: none !important;
    text-align: center !important;
  }

  .header-uper .contact-info {
    display: none;
  }

  .header-uper .logo {
    margin-bottom: 15px !important;
  }

  .header-uper .contact-info .item {
    text-align: left !important;
    margin-left: 40px !important;
    margin-right: 0px !important;
  }

  .header-uper .link-btn {
    float: none !important;
    margin-left: 0px !important;
  }
}

.whatsapp-fixed-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  box-sizing: border-box;
  padding: 0.75rem;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  border-radius: 100%;
  color: #fff !important;
  background-color: #25d366;
  z-index: 1050;
}

@media only screen and (max-width: 467px) {
  .main-header .logo {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
  }

  .main-header.style-two .search-box-btn {
    top: 95px !important;
  }
}

@media only screen and (max-width: 350px) {
  .feature-section .contact-info .item {
    padding-left: 50px !important;
    padding-right: 0px !important;
  }

  .feature-section .contact-info .icon-box {
    left: 10px !important;
  }
}
