//
// Sobre layout
//

section#doutor {
    .dados {
        @include media-breakpoint-down(md) {
            text-align: center;

            p.h1 {
                font-size: 35px !important;
                margin-bottom: 0;
            }
        }
    }
}
