// 
// Text columns
// 

.column-count-2 {
  column-count: 2;
  column-gap: $grid-gutter-width;
  @include media-breakpoint-down(md) {
    column-count: 1;
  }
}

.column-count-3 {
  column-count: 3;
  column-gap: $grid-gutter-width;
  @include media-breakpoint-down(md) {
    column-count: 1;
  }
}

.column-count-4 {
  column-count: 4;
  column-gap: $grid-gutter-width;
  @include media-breakpoint-down(md) {
    column-count: 1;
  }
}