//
// Guideline pages
//

.guideline-item-nav-title {
  font-weight: $font-weight-medium;
  @include font-size($h6-font-size);
  margin-bottom: $headings-margin-bottom / 2;
  padding-bottom: $headings-margin-bottom / 2;
  padding-left: $nav-link-padding-x;
  @include media-breakpoint-up(md) {
    list-style: none;
    &::-webkit-details-marker {
      display: none;
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: $btn-padding-y;
    padding-right: $btn-padding-x;
    padding-bottom: $btn-padding-y;
    padding-left: 0;
    @include font-size($btn-font-size);
    border: $btn-border-width solid transparent;
    border-radius: $btn-border-radius;
    @include transition($btn-transition);
    @include button-outline-variant($primary);
  }
}

.guideline-item-nav {
  --details-force-closed: (max-width: 63.9375em);
  @include font-size($font-size-sm);
  &__link {
    color: $body-color;
    text-decoration: none;
    border-radius: $border-radius-sm;
    margin-bottom: .25rem;
    &.active {
      color: $light;
      background-color: $primary;
      &:hover {
        color: $light;
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: none;
      color: $light;
      background-color: $primary;
    }
    &--child {
      @include font-size($font-size-sm * 0.85);
      line-height: 1.25;
      padding-top: $nav-link-padding-y / 1.5;
      padding-bottom: $nav-link-padding-y / 1.5;
      color: $body-color;
      &:last-child {
        margin-bottom: .5rem;
      }
    }
  }
  .nav {
    overflow: auto;
  }
}